import React, {Dispatch, FC, SetStateAction, useEffect, useRef, useState} from 'react';
import {additionalInfo, area, releaseBookedPeriod, space} from "../helpers/types";
import cardStyles from '../utilities/css/CardStyles.module.css';
import {
    handleSelectedCardArray,
    releasePeriodsFilter,
    upperCaseFirstLetter
} from "../helpers/helperFunctions";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {Icons} from "../helpers/Icons";
interface IProps
{
    space: space;
    area: area;
    selectedSpaces: space[] | space;
    setSelectedSpaces: Dispatch<SetStateAction<space[]>> | Dispatch<SetStateAction<space>>;
    setAdditionalInfo?: Dispatch<SetStateAction<additionalInfo>>;
    isOwnedPlace?: boolean;
    releaseClicked?: boolean;
    setReleaseClicked?: Dispatch<SetStateAction<boolean>>;
    setReleaseDateArray?: Dispatch<SetStateAction<releaseBookedPeriod[]>>;
    setOwnBookingDate?: Dispatch<SetStateAction<releaseBookedPeriod[]>>;
    selectedStartDate?: Date;
    selectedEndDate?: Date;
    spaceReservState?: string;
}

const SpaceCard: FC<IProps> = ({space, area, selectedSpaces, setSelectedSpaces, setAdditionalInfo, isOwnedPlace = false, releaseClicked, setReleaseClicked, setReleaseDateArray, selectedStartDate, setOwnBookingDate, selectedEndDate, spaceReservState}) =>
{
    const {t} = useTranslation();

    const [infoDisplayStyle, setInfoDisplayStyle] = useState<"none" | "flex">("none");

    const ref = useRef<HTMLDivElement>(null);

    const spaceAvailability = releasePeriodsFilter(space, selectedEndDate, selectedStartDate)

    const handleSpaceCardClicked = () =>
    {
        if((selectedSpaces as space[]).length === 1 && !(selectedSpaces as space[]).includes(space) ) (setSelectedSpaces as Dispatch<SetStateAction<space[]>>)([]);

        if(!isOwnedPlace) return handleSelectedCardArray(space, (selectedSpaces as space[]), (setSelectedSpaces as Dispatch<SetStateAction<space[]>>), area)
    }

    const handleAdditionalInfoClicked = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
    {
        e.stopPropagation();
        setAdditionalInfo!({clicked: true, space: space, area: area});
    }

    const handleManageOwnedSpace = () =>
    {
        (setSelectedSpaces as Dispatch<SetStateAction<space>>)((selectedSpaces as space));
        setReleaseClicked!(current => !current);
        setReleaseDateArray!(_.cloneDeep((selectedSpaces as space).releasePeriod).filter(period => period.active));
        setOwnBookingDate!(_.cloneDeep((selectedSpaces as space).ownerBookedPeriod).filter(period => period.active));
    }

    useEffect(() =>
    {
        if(ref.current !== null)
        {
            const el = ref.current;

            if(el.getBoundingClientRect().right > window.innerWidth)
            {
                el.classList.replace(cardStyles.type_info_left, cardStyles.type_info_right);
            } else
            {
                el.classList.replace(cardStyles.type_info_right, cardStyles.type_info_left);
            }
        }
    }, [infoDisplayStyle]);

    return (
        <div className={`${isOwnedPlace ? cardStyles.card_container_default : cardStyles.card_container} ${(!isOwnedPlace && (selectedSpaces as space[]).includes(space)) && cardStyles.card_selected}
        ${spaceReservState ? cardStyles[`${spaceReservState}`] : (spaceAvailability.length || !space.ownerEmail.length) ? cardStyles.accepted : cardStyles.pending }`} key={space._id}
             onClick={() => handleSpaceCardClicked()}>
            <div className={isOwnedPlace ? cardStyles.card_info_default : cardStyles.card_info}>
                <span className={cardStyles.header}>{space.name}</span>
                <div className={cardStyles.general_info}>
                    <span>{upperCaseFirstLetter(area.city)}</span>
                    <span>{area.address}</span>
                    <span>{t("spaceCard.electricCharger")}: {space.chargingPort ? t("app.yes") : t("app.no")}</span>

                {selectedStartDate !== undefined && selectedEndDate !== undefined &&
                    <div className={cardStyles.card_type_container}>{t("spaceCard.type")}:
                        {(spaceAvailability.length || !space.ownerEmail.length) ?
                            ` ${t("spaceCard.available")}` : ` ${t("spaceCard.reservation")}`}

                        <span onTouchStart={e => setInfoDisplayStyle(current=> current === "flex" ? "none" : "flex")}
                              onTouchEnd={e => e.stopPropagation()}
                              onClick={e => e.stopPropagation()}
                              onMouseEnter={() => setInfoDisplayStyle("flex")} onMouseLeave={() => setInfoDisplayStyle("none")} >{Icons.info}</span>

                        <div className={`${cardStyles.type_info_container} ${cardStyles.type_info_left}`} style={{display: infoDisplayStyle}} ref={ref}>
                            <div className={cardStyles.type_info}>
                                <span className={cardStyles.type_header}>{t("spaceCard.typeExplanation")}</span>
                                <span>{t("spaceCard.reservationType")}</span>
                                <span>{t("spaceCard.availableType")}</span>
                            </div>
                        </div>
                    </div>
                }
                </div>
                {isOwnedPlace &&
                    <>
                        <span>{t("spaceCard.released")}: {space.releasePeriod.filter(period => period.active).length ? t("app.yes") : t("app.no")}</span>
                        <span>{t("spaceCard.booked")}: {space.ownerBookedPeriod.filter(period => period.active).length ? t("app.yes") : t("app.no")}</span>
                        <span>{t("spaceCard.pending")}: {space.reservations.filter(reservation => reservation.state === "pending").length}</span>
                    </>
                }
            </div>
            <a className={cardStyles.card_controls} onClick={e => !isOwnedPlace ? handleAdditionalInfoClicked(e) : handleManageOwnedSpace()}>
                {!isOwnedPlace ? t("app.additionalInfo") : t("spaceCard.manageSpace")}</a>


        </div>
    );
}

export default SpaceCard;
