import {FaChevronLeft, FaChevronRight, FaEdit, FaRegClock, FaTimes, FaTrash, FaUserCircle} from "react-icons/fa";
import {BsCheckLg, BsExclamationLg, BsFillExclamationTriangleFill, BsInfoCircle} from "react-icons/bs";
import {GiHamburgerMenu} from "react-icons/gi";
import {TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted} from "react-icons/ti";
import {RiArrowDownSLine, RiArrowUpSLine, RiSearchLine} from "react-icons/ri";

export const Icons =
{
    edit: <FaEdit />,
    delete: <FaTrash />,
    close: <FaTimes />,
    clock: <FaRegClock />,
    error: <BsFillExclamationTriangleFill />,
    success: <BsCheckLg />,
    warning: <BsExclamationLg />,
    arrowLeft: <FaChevronLeft />,
    arrowRight: <FaChevronRight />,
    hamburgerMenu: <GiHamburgerMenu />,
    user: <FaUserCircle />,
    descArrow: <TiArrowSortedDown />,
    ascArrow: <TiArrowSortedUp />,
    unsorted: <TiArrowUnsorted />,
    arrowDown: <RiArrowDownSLine />,
    arrowUp: <RiArrowUpSLine />,
    search: <RiSearchLine />,
    "info": <BsInfoCircle />
}
