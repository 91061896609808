import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import Context from "../../helpers/Context";
import {useMsal} from "@azure/msal-react";
import {useTranslation} from "react-i18next";
import {filterExistingReleases, getDatesBetweenTwoDates, roundToNearest30Min} from "../../helpers/helperFunctions";
import {releaseBookedPeriod, reservation, space} from "../../helpers/types";
import {manageOwnedSpace} from "../../helpers/APIEnpoints";
import moment from "moment/moment";
import {v4} from "uuid";
import {addBusinessDays} from "date-fns";
import styles from "./css/OwnBookingAndReleaseDays.module.css";
import Button from "../../utilities/Button";
import {EndDate, StartDate} from "../../utilities/DatePickers";
import TimeModification from "./TimeModification";
import ReservationExistScreen from "../ReservationExistScreen";

interface IProps
{
    setReleaseClicked: Dispatch<SetStateAction<boolean>>;
    ownedSpace: space[];
    setOwnedSpace: Dispatch<SetStateAction<space[]>>
    selectedOwnedSpace: space;
    releaseDateArray: releaseBookedPeriod[];
    setReleaseDateArray: Dispatch<SetStateAction<releaseBookedPeriod[]>>;
    isOwnBooking: boolean;
}

const OwnBookingAndReleaseDays: FC<IProps> = ({setReleaseClicked, ownedSpace, setOwnedSpace, selectedOwnedSpace, releaseDateArray, setReleaseDateArray, isOwnBooking = false}) =>
{
    const {notificationDispatch, accessToken} = useContext(Context);
    const {accounts} = useMsal();
    const {t} = useTranslation();

    const [startDate, setStartDate] = useState<Date>(roundToNearest30Min(new Date(Date.now())));
    const [endDate, setEndDate] = useState<Date>(roundToNearest30Min(new Date(Date.now() + 300 * 60 * 1000)));

    const [isReleaseArrModified, setIsReleaseArrModified] = useState<boolean>(false);
    const [isModifyTime, setIsModifyTime] = useState<boolean>(false);
    const [modifyTime, setModifyTime] = useState<releaseBookedPeriod>({
        _id: "",
        active: false,
        endDate: new Date(),
        startDate: new Date()
    });

    const [isReservationExist, setIsReservationExist] = useState<boolean>(false);
    const [foundReservations, setFoundReservations] = useState<reservation[]>([]);
    const [showAdvanced, setShowAdvanced] = useState<boolean>(false);

    const [presetsSelected, setPresetsSelected] = useState<boolean>(false);
    const ownBookedDaysNum = releaseDateArray.filter(period => period.active || period.active === undefined).length;

    useEffect(() =>
    {
        if(isReservationExist)
        {
            document.body.style.overflow = "hidden";
        } else
        {
            document.body.style.overflow = "";
        }

    },[isReservationExist]);

    const handleOwnSpaceManage = async (spaceID: string, ownerEmail: string) =>
    {
        if(isReleaseArrModified)
        {
            let result: {existingReservationsMessage?: string,
                existingReservations?: reservation[], space: space} = await manageOwnedSpace(spaceID, ownerEmail, releaseDateArray, notificationDispatch, accessToken, isOwnBooking);

            if(!isOwnBooking && result.existingReservations!.length)
            {
                setFoundReservations(result.existingReservations!);
                setReleaseDateArray(result.space.ownerBookedPeriod);
                return setIsReservationExist(true);
            }

            const index = ownedSpace.findIndex(space => space._id === spaceID);
            isOwnBooking ? ownedSpace[index].ownerBookedPeriod = result.space.ownerBookedPeriod : ownedSpace[index].releasePeriod = result.space.releasePeriod;
            setReleaseDateArray([]);
        }

        handleClose();
    }

    const handleReleaseDateArrayAdd = (startDate: Date, endDate: Date, presetsSelected: boolean) =>
    {
        const isReleaseDaysExists = filterExistingReleases(selectedOwnedSpace.releasePeriod, startDate, endDate).length >= 1
        const isOwnerBookedExists = filterExistingReleases(selectedOwnedSpace.ownerBookedPeriod, startDate, endDate).length >= 1

        if(isOwnBooking && isReleaseDaysExists)
        {
            return notificationDispatch({
                type: "ADD_NOTIFICATION",
                payload:
                    {
                        id: v4(),
                        type: "error",
                        message: t("user.releaseDaysExists")
                    }
            });
        }

        if(!isOwnBooking && isOwnerBookedExists)
        {
            return notificationDispatch({
                type: "ADD_NOTIFICATION",
                payload:
                    {
                        id: v4(),
                        type: "error",
                        message: t("user.ownerBookedExists")
                    }
            });
        }

        if(isOwnBooking && filterExistingReleases(selectedOwnedSpace.reservations.filter(reservation => reservation.state !== "rejected"), startDate, endDate).length)
        {
            return notificationDispatch({
                type: "ADD_NOTIFICATION",
                payload:
                    {
                        id: v4(),
                        type: "error",
                        message: t("backEnd.ownBookingReservationsExists")
                    }
            });
        }

        for (const date of releaseDateArray)
        {
            if((moment(date.startDate).isSame(startDate, "minute") ||
                moment(date.endDate).isSame(endDate, "minute") ||
                moment(date.startDate).isBetween(startDate, endDate,"minute") ||
                moment(startDate).isBetween(date.startDate, date.endDate,"minute")) && (date.active || date.active === undefined))
            {
                return notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "error",
                            message: t("user.releaseDatesOverlap")
                        }
                });
            }
        }

        if(isOwnBooking && (ownBookedDaysNum >= 10 || moment(endDate).diff(startDate, "days") > 10))
        {
            return notificationDispatch({
                type: "ADD_NOTIFICATION",
                payload:
                    {
                        id: v4(),
                        type: "error",
                        message: t("backEnd.ownBookingReservationsLimitError")
                    }
            });
        }

        if(moment(endDate).diff(startDate, "days") >= 2)
        {
            for (const date of getDatesBetweenTwoDates(startDate, endDate, presetsSelected))
            {
                setReleaseDateArray(prev => [...prev, {startDate: roundToNearest30Min(date), endDate: roundToNearest30Min(moment(date).endOf("day").toDate())}])
            }
        } else
        {
            setReleaseDateArray(prev => [...prev, {startDate: startDate, endDate: endDate}]);
        }

        setStartDate(roundToNearest30Min(new Date(Date.now())));
        setEndDate(roundToNearest30Min(new Date(Date.now() + 30 * 60 * 1000)));
        if(!isReleaseArrModified) setIsReleaseArrModified(true);
    }

    const handleReleaseDateArrayDelete = (releaseStartDate: Date, releaseEndDate: Date) =>
    {
        const index = releaseDateArray.findIndex(date => date.startDate === releaseStartDate);
        const existingReservations = selectedOwnedSpace.reservations.filter(reservation =>
            (moment(reservation.startDate).isSame(releaseStartDate, "minute") ||
                (moment(reservation.endDate).isSame(releaseEndDate, "minute") && moment(reservation.startDate).isSameOrAfter(releaseStartDate)) ||
                moment(reservation.startDate).isBetween(releaseStartDate, releaseEndDate,"minute")))
            .filter(reservation => reservation.state !== "rejected");

        if(!isOwnBooking && existingReservations.length && releaseDateArray[index].active !== undefined)
        {
            setIsReservationExist(current => !current);
            setFoundReservations(existingReservations);
            return;
        }

        setReleaseDateArray(current =>
        {
            if(current[index].active !== undefined)
            {
                current[index].active = false;
                return [...current];
            } else
            {
                return current.filter(period => !(moment(period.startDate).isSame(releaseStartDate, "minute") && period.active === undefined))
            }
        });

        if(!isReleaseArrModified) setIsReleaseArrModified(true);
    }

    const handleClose = () =>
    {
        setReleaseClicked(current => !current);
        setPresetsSelected(false);
    }

    const handleReleaseDateOptions = (date: string) =>
    {
        switch (date)
        {
            case "today":
                handleReleaseDateArrayAdd(roundToNearest30Min(new Date(Date.now())), roundToNearest30Min(moment(new Date(Date.now())).endOf("day").toDate()), true);
                break;
            case "tomorrow":
                const businessDay = addBusinessDays(new Date(Date.now()), 1);
                handleReleaseDateArrayAdd(moment(businessDay).startOf("day").toDate(), roundToNearest30Min(moment(businessDay).endOf("day").toDate()), true);
                break;
            case "week":
                handleReleaseDateArrayAdd(new Date(Date.now()), roundToNearest30Min(moment(new Date(Date.now())).add(1, "week").toDate()), true);
                break;
        }
    }

    return (
        <div className={styles.release_main_container}>
            <div className={styles.release_container}>

                <div className={styles.release_period_controls}>
                    <span className={styles.header}>{isOwnBooking ? t(`user.selectOwnReservationDate`) : t(`user.selectReleaseDate`)} </span>
                    <Button btnMini={true} disabled={isOwnBooking && ownBookedDaysNum >= 10} onClick={() => handleReleaseDateOptions("today")}>{t("user.today")}</Button>
                    <Button btnMini={true} disabled={isOwnBooking && ownBookedDaysNum >= 10} onClick={() => handleReleaseDateOptions("tomorrow")}>{t("user.tomorrow")}</Button>
                    <Button btnMini={true} disabled={isOwnBooking && ownBookedDaysNum >= 10} onClick={() => handleReleaseDateOptions("week")}>{t("user.week")}</Button>
                    {(window.innerWidth >= 1000 || showAdvanced) &&
                        <>
                            <span className={styles.header}>{t("app.chooseCustomTimes")}</span>
                            <div className={styles.custom_dates_controllers}>
                                <StartDate startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} setPresetsSelected={setPresetsSelected} />
                                <EndDate endDate={endDate} setEndDate={setEndDate} startDate={startDate} setPresetsSelected={setPresetsSelected} isOwnBooking={isOwnBooking}/>
                            </div>
                        </>
                    }
                    {(window.innerWidth >= 1000 || showAdvanced) && <Button btnMini={true} disabled={isOwnBooking && ownBookedDaysNum >= 10} onClick={() => handleReleaseDateArrayAdd(startDate, endDate, presetsSelected)}>{isOwnBooking ? t("user.addOwnReservationDate") : t("user.addReleaseDate")}</Button>}
                </div>

                {window.innerWidth <= 1000 && <div className={styles.advanced_options}><hr /><Button btnMini={true} btnType="secondary" iconType={showAdvanced ? "arrowUp" : "arrowDown"} onClick={() => setShowAdvanced(current => !current)}>{t(`booking.${showAdvanced ? "less" : "more"}`)}</Button><hr /></div>}

                <div className={styles.release_dates_container}>
                    <span className={styles.header}>{isOwnBooking ? t(`user.ownReservationDays`) : t(`user.releaseDays`)} {isOwnBooking ? `(${t("user.limit")}: ${ownBookedDaysNum} / 10)` : null}</span>
                    <table>
                        <thead>
                        <tr>
                            <th>{t("app.startDate")}</th>
                            <th>{t("app.endDate")}</th>
                            <th>{t("app.action")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {releaseDateArray.filter(period =>
                        {
                            if(period.active !== undefined) return period.active;

                            return period;
                        }).length ?
                            releaseDateArray.filter(period =>
                            {
                                if(period.active !== undefined) return period.active;

                                return period;
                            }).sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
                                .map((date, index) =>
                                    <tr key={index} className={`${date.active === undefined ? styles.release_date_tr_new : ""}`}>
                                        <td data-label={`${t("app.startDate")}: `}>{moment(date.startDate).format("DD.MM.yyyy HH:mm")}</td>
                                        <td data-label={`${t("app.endDate")}: `}>{moment(date.endDate).format("DD.MM.yyyy HH:mm")}</td>
                                        <td className={styles.card_controls}>
                                            {date.active === undefined && <Button btnMini={true} iconType="clock" onClick={() =>
                                            {
                                                setModifyTime(date);
                                                setIsModifyTime(current => !current);
                                            }}>{t("user.changeTime")}</Button>}
                                            <Button btnMini={true} btnType="danger" iconType="delete" onClick={() => handleReleaseDateArrayDelete(date.startDate, date.endDate)}>{t("app.delete")}</Button>
                                        </td>
                                    </tr>
                                ) :
                            <tr><td>{isOwnBooking ? t("user.noOwnReservationDates") : t("user.noReleaseDates")}</td></tr>
                        }
                        </tbody>
                    </table>
                </div>
            </div>

            <div className={styles.release_controls}>
                <Button onClick={() => handleClose()} btnType="danger">{t("app.cancel")}</Button>
                <Button onClick={() => handleOwnSpaceManage(selectedOwnedSpace._id, accounts[0].username)} disabled={!isReleaseArrModified || (isOwnBooking && ownBookedDaysNum > 10)}>{t("app.save")}</Button>
            </div>

            {isModifyTime &&
                <TimeModification modifyTime={modifyTime}
                                  setIsModifyTime={setIsModifyTime}
                                  releaseDateArray={releaseDateArray} />
            }

            {isReservationExist && <ReservationExistScreen setIsReservationExist={setIsReservationExist} reservations={foundReservations}/>}
        </div>
    );
}

export default OwnBookingAndReleaseDays;
